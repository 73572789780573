import React, { Component } from 'react';
import fetch from '../api/FetchData';

import ReactTable from "react-table";
import { ReactTableDefaults } from 'react-table';

import { Link } from 'react-router-dom';
import "react-table/react-table.css";
import './Acquisitions.css';
import classes from '../hoc/DediconStyle/Dedicon.module.css';

import arrow from '../assets/arrow-right.svg';
import logo from '../assets/Beeldmerk_Dedicon.png';
import infoimage from '../assets/info.png';
/**
 * 
 * Component contains a react table with some custom styling showing the conversions Dedicon has made since 01-01-2019
 * @class containers/Acquisitions
 * @requires api/FetchData 
 * @requires react-table
 */
class Acquisitions extends Component {
    state = {
        columns: [],
        values: [],
        caseSensitive: false,//default to false
        filtered: [],
        loading: true
    }
    /**
     * table properties
     */
    tableProps = {
        filterable: true,
        filtered: this.state.filtered,
        defaultPageSize: 25,
        className: "-striped -highlight",
        previousText: 'Vorige',
        nextText: 'Volgende',
        pageText: 'Pagina',
        rowsText: 'rijen',
        ofText: 'van',
        loadingText: 'Data ophalen...',
        noDataText: 'Geen data...'
    }

    /**
     * start http get request 
     */
    componentWillMount() {
        Object.assign(ReactTableDefaults, this.tableProps);

        fetch().then((result) => {
            if (result.headers.length > 1) {
                let orderedHeads = this.orderColumns(result.headers);
                let columns = this.buildColumns(orderedHeads);

                this.setState({
                    columns: columns,
                    values: result.values,
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        });
    }

    /**
     * update filter and set input field
     */
    onFilteredChange = (value, accessor) => {
        //   if (!accessor()) return false;

        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered && filtered.length > 0) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    /**
     * default filter method on every column
     */
    defaultFilterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        if (typeof filter.value === "object") {
            return row[id] !== undefined
                ? filter.value.indexOf(row[id]) > -1
                : true;
        } else {
            return row[id] !== undefined
                ? this.state.caseSensitive ? String(row[id]).indexOf(filter.value) > -1 : String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
                : true;
        }
    };

    /**
     * default sort method
     */
    defaultSortMethod = (a, b, desc) => {
        // force null and undefined to the bottom
        a = a === null || a === undefined ? '' : a
        b = b === null || b === undefined ? '' : b
        // force any string values to lowercase
        a = typeof a === 'string' ? a.toLowerCase() : a
        b = typeof b === 'string' ? b.toLowerCase() : b
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
            return 1
        }
        if (a < b) {
            return -1
        }
        return 0
    };

    /**
     * create react-table column headers
     */
    buildColumns = (headers) => {
        let columns = [];
        headers.forEach(head => {
            columns.push({
                Header: () => {
                    return <div><span>{this.modifyHeaderName(head)}</span>
                        <img src={arrow} className='arrow' alt='sortering' /></div>
                },
                accessor: head
            })
        });
        return columns;
    }
    /**
     * rename column header
     * @param {String} head name of column
     * @return {String} tmpHead new column name
     */
    modifyHeaderName = (head) => {
        var tmpHead = '';
        switch (head.toLowerCase()) {
            case 'uitleenklaardatum':
                tmpHead = "Datum beschikbaar";
                break;
            case "jaarvanuitgave":
                tmpHead = 'Jaar van uitgave';
                break;
            default:
                tmpHead = head;
                break;
        }
        return tmpHead;
    }

    /**
     * set order columns by predefined order
     * @param {Object} columns
     * @return {Object} ordered 
     * 
     */
    orderColumns = (columns) => {

        var orderingByArray = ['titel', 'auteur', 'uitgever', 'uitleenklaardatum', 'jaarvanuitgave', 'isbn13', 'productsoort'];

        let result = columns.map(function (item) {
            var n = orderingByArray.indexOf(item.toLowerCase());
            return [n, item]
        }).sort().map(function (j) { return j[1] })
        return result;

    }
    /**
     * toggle case sensitive filter
     */
    setCaseSensitive = () => {
        this.setState({
            caseSensitive: !this.state.caseSensitive
        });

        //trigger filter
        this.setState({
            filtered: [...this.state.filtered]
        });
    }

    render() {
        return <React.Fragment>

            <h1 className={classes.Header}>Register Omgezette Werken Algemene Lectuur</h1>

            <Link className={classes.Link} to="/info">
                <img className={classes.Beeldmerk} alt='Uitleg over deze pagina' src={logo} />
                <img className={classes.Info} alt='info' src={infoimage} />
            </Link>
            <ul>
                <li>Onderstaande tabel is sorteerbaar door op de kolomkop te klikken.</li>
                <li>Iedere kolom kan worden gefilterd door het tekstveld onder de kolomkop in te vullen.</li>
            </ul>
            {this.state.values ? <ReactTable
                style={{ padding: '10px 20px 10px 20px' }}
                // onFetchData={(state, instance) => {
                //     this.setState({ loading: true });
                // }}
                columns={this.state.columns}
                data={this.state.values}

                onFilteredChange={(filtered, column, value) => {
                    this.onFilteredChange(value, column.id || column.accessor);
                }}

                defaultFilterMethod={this.defaultFilterMethod}
                defaultSortMethod={this.defaultSortMethod}
                loading={this.state.loading}
                noDataText={this.state.loading ? '' : 'Geen data...'}
            /> : null}
        </React.Fragment>

    }
}

export default Acquisitions;