import axios from 'axios';

/**
 * ajax get request with axios and fetch headers and body from response object 
 * calls a php script that retrieves an xml stream from the ftp server
 * @class api/FetchData
 * @requires axios http client
 */
const fetch = () => {
  return new Promise((resolve, reject) => {
    let requestUrl = process.env.REACT_APP_HTTP_PROXY + process.env.REACT_APP_API;
    if (window.location.protocol === 'https:') {
      requestUrl = requestUrl.replace('http:', 'https:');
    }
    axios.get(requestUrl)
      .then((result) => {

        if (result.data) {
          let headers = Object.keys(result.data[0]);
          let values = result.data;
          const objResult = { headers, values };
          return resolve(objResult);
        } else {
          return resolve('no data');
        }
      }).catch((e) => {
        return reject(e);
      });
  });
}

export default fetch;