import React, { Component } from 'react';
import classes from '../hoc/DediconStyle/Dedicon.module.css';
/**
 * 
 * Component contains explanation of why this page exists
 * @class components/Info
 */
class Info extends Component {

    render() {
        return (
            <React.Fragment>

                <h1 className={classes.Header} >Toelichting bij het register van omgezette werken.</h1>
                <div className={classes.Manual}>
                    <p>
                        In dit register worden alle werken van algemene lectuur getoond die sinds 1 januari 2019 zijn omgezet naar een toegankelijke versie. De werken zijn vindbaar op titel, auteur, uitgever en ISBN. De datum beschikbaar verwijst naar de dag waarop het omgezette werk is toegevoegd aan de collectie van de bibliotheekvoorziening voor personen met een leeshandicap.<br />
                        Voor mensen die niet (meer) in staat zijn gedrukte of digitaal gepubliceerde werken te lezen is een Nederland een bibliotheekvoorziening voor personen met leeshandicap beschikbaar. Voortgekomen uit het blindenbibliotheekwerk is de huidige voorziening onder de naam Aangepast Lezen sinds 2015 een verantwoordelijkheid van de Koninklijke Bibliotheek (KB).
                    </p><p>
                        Deze bibliotheekvoorziening werkt vanuit dezelfde publieke waarden als de openbare bibliotheek en is verankerd in <a target="blank" href="https://wetten.overheid.nl/BWBR0035878/2015-01-01#Hoofdstuk1">de Wet stelsel openbare bibliotheekvoorzieningen (Wsob; art 9c)</a>, maar is vrijgesteld van het betalen van een leenrechtvergoeding.<br />
                        Onder regie van de KB geven de volgende organisaties uitvoering aan deze bibliotheekvoorziening: Bibliotheekservice Passend Lezen (BPL) voor de collectie en de front-office voor de leden, Dedicon en de CBB voor de productie van de aangepast lezen versies van gepubliceerde werken in braille, grootletter, gesproken of digitale vorm.
                    </p><p>
                        Op basis van een beperking in de <a target="blank" href="https://wetten.overheid.nl/BWBR0001886/2018-10-11">Auteurswet (art.15 j t/m m)</a> kunnen Dedicon en CBB zonder voorafgaande toestemming een auteursrechtelijk beschermde werk omzetten naar een aangepast lezen kopie en deze toevoegen aan de uitleencollectie van BPL en/of de lokale bibliotheek. De kopie kan uitsluitend ten goede komen aan personen met een leeshandicap. De uitvoering van deze beperking is in goede afstemming met de rechthebbenden vervat in een regeling die overeengekomen is tussen de KB, de Mediafederatie en Dedicon; <a href="https://mediafederatie.nl/app/uploads/2019/01/Regeling-Toegankelijke-lectuur-voor-mensen-met-een-leesbeperking-2019-2021.pdf" target="blank">de Regeling Toegankelijke Lectuur voor mensen met een leesbeperking 2019-2021</a>. In tegenstelling tot eerdere regelingen is in deze Regeling de meldingsplicht van een voorgenomen omzetting komen te vervallen. In de plaats daarvan is afgesproken een openbaar toegankelijk register te publiceren. Ook is afgesproken dat partijen zich zullen inspannen om te controleren of een toegankelijk werk commercieel of anderszins beschikbaar is zodat dubbele productie wordt vermeden.<br />
                        Met dit register van omgezette werken Algemene Lectuur draagt Dedicon ten behoeve van betrokken partijen zorg voor de openbaar toegankelijke registratie van werken die zijn omgezet voor de bibliotheekvoorziening voor mensen met een leesbeperking (conform Art.3 Regeling). Het register bevat uitsluitend werken van Algemene Lectuur (fictie en non-fictie).
                    </p>
                    <p>Stuur een e-mail naar <a href="mailto:info@dedicon.nl">info@dedicon.nl</a> als u vragen of opmerkingen heeft met betrekking tot dit register of de Regeling Toegankelijke Lectuur.
                    </p>
                </div>
            </React.Fragment>

        )
    }
}

export default Info;