import React, { Component } from 'react';
import classes from './Dedicon.module.css';
import Logo from '../../assets/logo.svg';

/**
 * 
 * wrapper to style the page in a Dedicon way
 * It contains a logo a header an two divs, it uses a css module
 * @class hoc/DediconStyle
 */
class dediconStyle extends Component {
    render() {

        return (
            <div className={classes.Generic}>
                <div className={classes.HeaderContainer}>
                    <img src={Logo} alt='dedicon logo' className={classes.Logo} />

                    <div>
                        {this.props.children}
                    </div>

                    <div className={classes.FooterText}>Dedicon maakt het mogelijk.
                <div className={classes.SiteSloganFooter}></div>
                    </div>
                </div>
            </div>)
    }
}
export default dediconStyle;