import React, { Component } from 'react';
import Acquisitions from './containers/Acquisitions';
import Info from './components/Info';
import DediconStyle from './hoc/DediconStyle/DediconStyle';
import { Route, HashRouter } from 'react-router-dom';

import './App.css';

/**
 * @requires Acquisitions
 * @requires DediconStyle
 * @class App
 */
class App extends Component {
  /**
   * append current version to index
   */
  componentWillMount() {

    const meta = document.createElement('meta');
    meta.setAttribute('name', 'version');
    meta.setAttribute('content', process.env.REACT_APP_VERSION);
    document.head.append(meta);
  }

  render() {
    return (

      <DediconStyle >
        <HashRouter>
          <div>
            <Route path="/info" component={Info} />
            <Route path="/" exact component={Acquisitions} />
          </div>
        </HashRouter>
      </DediconStyle>
    )
  }
}


export default App;
